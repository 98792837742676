"use strict";

var form = document.querySelector(".form__contact-us");
var submitButton = form.querySelector('button[type="submit"]');
var notification = document.querySelector(".notification");
var fields = [{
  id: "#fname",
  required: true
}, {
  id: "#lname",
  required: true
}, {
  id: ".custom-select",
  type: "custom-select",
  required: true,
  defaultValue: "Select..."
}];
var handleEmptyField = function handleEmptyField(field) {
  var element = form.querySelector(field.id);
  var value = field.type === "custom-select" ? element.querySelector("select").value : element.value.trim();
  if (field.required) {
    if (!value || field.defaultValue && value === field.defaultValue) {
      element.classList.add("empty-field");
    } else {
      element.classList.remove("empty-field");
    }
  }
};
var handleSubmitForm = function handleSubmitForm(evt) {
  evt.preventDefault();
  var hasEmptyFields = false;
  fields.forEach(function (field) {
    handleEmptyField(field);
    if (field.required) {
      var value = field.type === "custom-select" ? form.querySelector(field.id).querySelector("select").value : form.querySelector(field.id).value.trim();
      if (!value || field.defaultValue && value === field.defaultValue) {
        hasEmptyFields = true;
      }
    }
  });
  if (hasEmptyFields) {
    return;
  }
  var firstName = form.querySelector("#fname").value;
  var lastName = form.querySelector("#lname").value;
  var service = form.querySelector("#service").value;
  var message = form.querySelector("textarea").value;
  var formData = {
    firstName: firstName,
    lastName: lastName,
    service: service,
    message: message
  };
  fetch("https://info.expiriadev.cx.ua/info-mail", {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(function () {
    form.reset();
    form.classList.add("hidden");
    notification.classList.add("show");
  });
};
submitButton.addEventListener("click", handleSubmitForm);